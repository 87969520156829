import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isLoadingData: false,
  isInititalDataFetched: false,
  error: null,
  step: 0,
  details: {
    currentState: "",
    goal: "",
    service: "",
    challenges: [],
    concern: [],
    about: {},
    userDetails: {},
  },
  isNavigationVisible: false,
  isCompleted: false,
};

export const StartupSlice = createSlice({
  name: "startupForm",
  initialState,
  reducers: {
    updateStep: (state, action) => {
      const { payload } = action;
      state.step = payload ?? 0;
    },
    updateDetails: (state, action) => {
      const { payload } = action;
      const { field, value } = payload;
      state.details[field] = value;
    },

    showNavigation: (state) => {
      state.isNavigationVisible = true;
    },
    hideNavigation: (state) => {
      state.isNavigationVisible = false;
    },
  },
});

export const { updateDetails, updateStep, showNavigation, hideNavigation } =
  StartupSlice.actions;

export default StartupSlice.reducer;
