import { useEffect } from "react";
import Box from "components/Welcome/Box";
import { getAllStartups } from "config/APIs/startups";
import { useDispatch, useSelector } from "react-redux";
import { updateStartups } from "redux/startups";

export default function WelcomePage() {
  const dispatch = useDispatch();
  const { startups } = useSelector((state) => state.startup);

  useEffect(() => {
    (async () => {
      await getStartups();
    })();
  }, []);

  const getStartups = async () => {
    try {
      const { data } = await getAllStartups();

      dispatch(updateStartups(data?.data));
    } catch (error) {
      console.log(error.response.data);
    }
  };
  return (
    <section
      className="startupBg relative h-screen w-full overflow-x-hidden overflow-y-hidden"
      id="WelcomePage"
    >
      <div className="w-[430px] h-[255px] rotate-[-160deg] absolute left-[-80px] bottom-[-135px] bg-[#696763] blur-[196px]"></div>
      <div className="w-[365px] h-[230px] rotate-[150deg] absolute right-[-90px] top-[-94px] bg-[#000] blur-[196px]"></div>

      <div className="flex lg:justify-center items-center h-screen px-4">
        <Box startups={startups} />
      </div>
      <div className="w-full text-primary-neutral-200 text-center font-poppins text-xs leading-4 tracking-[0.24px] flex flex-row items-center justify-center p-6 fixed bottom-0 left-0 right-0">
        caarya © 2024
      </div>
    </section>
  );
}
