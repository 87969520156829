import { configureStore } from "@reduxjs/toolkit";
import startupsReducer from "redux/startups";
import startupFormReducer from "redux/startupForm";
import getInTouchReducer from "redux/getInTouch";

const store = configureStore({
  reducer: {
    startup: startupsReducer,
    startupForm: startupFormReducer,
    getInTouch: getInTouchReducer,
  },
});

export default store;
