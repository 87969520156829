import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  details: null,
  startup: null,
  information: {},
};

export const GetInTouchSlice = createSlice({
  name: "getInTouch",
  initialState,
  reducers: {
    updateDetails: (state, action) => {
      state.details = action.payload?.details;
    },
    addStartup: (state, action) => {
      state.startup = action.payload?.startup;
    },
    updateInformation: (state, action) => {
      const key = action.payload?.key;
      const value = action.payload?.value;
      state.information[key] = value;
    },
  },
});

export const { updateDetails, addStartup, updateInformation } =
  GetInTouchSlice.actions;

export default GetInTouchSlice.reducer;
