import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { updateStep } from "redux/startupForm";
import GradientButton from "components/Common/Button/GradientButton";

const FirstStep = () => {
  const dispatch = useDispatch();
  const { step } = useSelector((state) => state.startupForm);
  return (
    <div className="flex flex-col gap-8  p-6 md:p-0 pt-36 items-center justify-center text-center relative w-full max-w-5xl mx-auto ">
      <h1 className="font-poppins font-light  text-5xl md:text-6xl  ">
        Hello There!
      </h1>
      <p className="font-inter text-md md:text-lg leading-8 md:leading-10 font-light tracking-wide w-full max-w-3xl ">
        We at Caarya, are committed to solving the talent needs of startups by
        helping them build functional and delivery oriented teams
      </p>
      <GradientButton
        text="Next"
        icon={<BiRightArrowAlt className="text-lg" />}
        onClick={() => dispatch(updateStep(step + 1))}
      />
    </div>
  );
};

export default FirstStep;
