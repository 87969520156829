const { sendOtpToEmail, verifyOtp } = require("config/APIs/startups");

// sends OTP to the email entered by the user
export const sendOTP = async (startupID, email) => {
  try {
    const { data } = await sendOtpToEmail(startupID, {
      email,
    });
    return { data, error: null };
  } catch (error) {
    console.log(error.response.data);
    return {
      data: null,
      error: error.response.data,
    };
  }
};

// verifies the OTP entered by the user
export const verifyOTP = async (startupID, email, otp) => {
  try {
    const { data } = await verifyOtp(startupID, {
      email,
      otp: otp?.join(""),
    });
    return { data, error: null };
  } catch (error) {
    console.log(error.response.data);
    return {
      data: null,
      error: error.response.data,
    };
  }
};
