import toast from "react-hot-toast";

const style = {
  borderRadius: "10px",
  background: "#333",
  color: "#fff",
};

export const notify = (message, icon) =>
  toast(message, {
    icon,
    duration: 4000,
    style,
  });

export const notifyError = (message) =>
  toast.error(message, {
    duration: 4000,
    style,
  });

export const notifySuccess = (message) =>
  toast.success(message, {
    duration: 4000,
    style,
  });
