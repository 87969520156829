import { BiLinkExternal } from "react-icons/bi";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { useEffect, useState } from "react";
import { getStartupById } from "config/APIs/startups";
import { notifyError, notifySuccess } from "helpers/toast";
import { useNavigate, useParams } from "react-router-dom";
import Founder from "components/Startup/Founder";
import Product from "components/Startup/Product";
import moment from "moment";
import { CiGlobe } from "react-icons/ci";
import { MdPeopleAlt } from "react-icons/md";

export default function StartupPage() {
  const navigate = useNavigate();
  const [startup, setStartup] = useState(null);
  const { startupID: id } = useParams();
  useEffect(() => {
    (async () => {
      const { data, error } = await getStartup(id);
      if (error) {
        notifyError(error?.message);
        return;
      }
      setStartup(data);
    })();
  }, []);

  const getStartup = async (id) => {
    try {
      const { data } = await getStartupById(id);
      // notifySuccess("Startup fetched successfully!");
      return {
        data: data?.data,
        error: null,
      };
    } catch (error) {
      console.log(error.response.data);
      return {
        data: null,
        error: error.response.data,
      };
    }
  };
  return (
    <main className="startupBg">
      <div className="max-w-7xl mx-auto px-4 py-20 flex flex-col items-center space-y-10">
        <h1 className="heading-text font-poppins text-2xl lg:text-[32px] font-light text-center">
          Check out your <span className="font-medium">Startup Profile</span>
        </h1>
        <section
          id="startupBox"
          className="relative rounded-2xl w-full overflow-x-hidden py-5 px-5 lg:p-[40px] flex flex-col items-center space-y-10 lg:space-y-20"
        >
          <div className="flex flex-col gap-4 justify-center items-center w-full my-4">
            <img
              src="/assets/logos/groww.png"
              alt="Startup Name"
              className="w-[80px] h-[80px]"
            />
            <div className="flex flex-col items-center gap-4">
              <h1 className="text-2xl text-white font-medium leading-9 text-primary-gray-900 font-poppins">
                {startup?.name}
              </h1>
              <p className="text-base text-white font-inter">
                {startup?.industry}
              </p>
              <p className="text-sm text-[#ffffffb3] font-inter max-w-3xl leading-[170%]">
                {startup?.description}
              </p>
            </div>
          </div>
          <div className="mt-4 w-full px-4 grid gap-6 lg:grid-cols-3">
            <div className="flex text-[#E7E6E5]">
              <CiGlobe className="inline-block mr-4 text-lg" />
              <p className="text-sm">{startup?.website ?? "N/A"}</p>
            </div>
            <div className="self-stretch w-[2px] rounded-[56px] bg-[#ffffff4d]"></div>
            <div className="flex text-[#E7E6E5]">
              <FaInstagram className="inline-block mr-4 text-lg" />
              <p className="text-sm">{startup?.instagram ?? "N/A"}</p>
            </div>
            <div className="self-stretch w-[2px] rounded-[56px] bg-[#ffffff4d]"></div>
            <div className="flex text-[#E7E6E5]">
              <FaLinkedin className="inline-block mr-4 text-lg" />
              <p className="text-sm">{startup?.linkedin ?? "N/A"}</p>
            </div>
          </div>

          <div className="py-4 w-full">
            <div className="grid lg:grid-cols-3 gap-8 mt-6">
              <div className="flex flex-col gap-4 py-8 px-6 text-center bg-[#ffffff14] rounded-2xl">
                <div className="flex items-center justify-center gap-4">
                  <MdPeopleAlt className="inline-block text-white text-5xl" />
                  <h4 className="text-center text-5xl font-medium text-white ">
                    {startup?.instagram ? `@${startup?.instagram}+` : "0"}
                  </h4>
                </div>
                <p className="font-inter text-2xs text-neutral-300 font-light">
                  Company Size as of dd-mm-yy
                </p>
              </div>
              <div className="flex flex-col gap-4 py-8 px-6 text-center bg-[#ffffff14] rounded-2xl">
                <div className="flex items-center justify-center gap-4">
                  <FaInstagram className="inline-block text-white text-5xl" />
                  <h4 className="text-center text-5xl font-medium text-white ">
                    {startup?.instagram ? `@${startup?.instagram}+` : "0"}
                  </h4>
                </div>
                <p className="font-inter text-2xs text-neutral-300 font-light">
                  Instagram Followers as of dd-mm-yy
                </p>
              </div>{" "}
              <div className="flex flex-col gap-4 py-8 px-6 text-center bg-[#ffffff14] rounded-2xl">
                <div className="flex items-center justify-center gap-4">
                  <FaLinkedin className="inline-block text-white text-5xl" />
                  <h4 className="text-center text-5xl font-medium text-white ">
                    {startup?.linkedin ? `@${startup?.linkedin}+` : "0"}
                  </h4>
                </div>
                <p className="font-inter text-2xs text-neutral-300 font-light">
                  LinkedIn Followers as of dd-mm-yy
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="py-8 flex flex-col items-center space-y-8 text-white text-center">
          <div className=" flex flex-col items-center space-y-2">
            <p className="font-poppins text-sm font-semibold">
              Not Your Startup?
            </p>
            <p className="font-inter text-lg font-light">
              Don’t worry you can give your details to out Startup Solutions
              Expert later
            </p>
          </div>
          <button
            className="rounded-xl bg-gradient-to-b from-red-500 to-yellow-500 text-white px-8 text-sm py-4 relative shadow-lg mx-auto"
            onClick={() => {
              navigate("/options");
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </main>
  );
}
