import axios from "axios";
import config from "./APIEndpoints";

const CAARYA_ME_BE = "https://caaryame-backend-tk4gwh76qa-el.a.run.app/api/v1";
const BASE_API_URL = config.getEndpoint();
const ADMIN_API_URL = BASE_API_URL + "/api/v1";
const NEXUS_ENDPOINT = BASE_API_URL + "/api/v1/app/nexus/";

export { BASE_API_URL, NEXUS_ENDPOINT };

export const feBaseLink = {
  applicationForm: "https://caarya-apps.web.app",
  caaryaLive: "https://caarya.live",
};

// Util
export const getHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
};

export const getMultipartHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "multipart/form-data",
    },
  };
};

export const instance = axios.create({
  baseURL: ADMIN_API_URL,
});

export const nexusInstance = axios.create({
  baseURL: NEXUS_ENDPOINT,
});

export const meInstance = axios.create({
  baseURL: CAARYA_ME_BE,
});

export const refreshToken = () => nexusInstance.get("/auth/token", getHeader());
