import axios from "axios";
import { instance } from "config";
import { meInstance } from "config";
import { nexusInstance, getHeader } from "config";

export const getAllStartups = (query = {}) => {
  return instance.get(
    `/public/nexus/startup?` + new URLSearchParams(query),
    getHeader()
  );
};

export const getStartupById = (id) => {
  return nexusInstance.get(`/startup/${id}`, getHeader());
};

/**
 * Sends OTP to the email
 * @param {string} startupID
 * @param {object} data
 * @param {string} data.email
 */

export const sendOtpToEmail = (startupID, data) => {
  return nexusInstance.post(
    `/startup/${startupID}/send-otp`,
    data,
    getHeader()
  );
};

/**
 * Verifies the OTP sent to the email
 * @param {string} startupID
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.otp
 */

export const verifyOtp = (startupID, data) => {
  return nexusInstance.post(
    `/startup/${startupID}/verify-otp`,
    data,
    getHeader()
  );
};

export const saveInfo = (data) => {
  return instance.patch(`/public/nexus/startup`, data);
};

export const ftpCount = () => {
  return axios.get(
    `https://chronos-prod-tk4gwh76qa-el.a.run.app/api/v1/app/stargaze/count`
  );
};
