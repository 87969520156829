import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "redux/startupForm";

const DetailsForm = ({ field }) => {
  const { details } = useSelector((state) => state.startupForm);
  const userDetails = details[field];
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const value = { ...userDetails, [e.target.name]: e.target.value };
    dispatch(updateDetails({ field, value }));
  };

  return (
    <div className="md:px-20 w-full">
      <div className=" p-4 md:p-6 md:px-10 flex flex-col gap-8 ">
        <div className="flex flex-col gap-2 text-center">
          <h1 className="text-xl font-poppins tracking-[2%]">One Last Step</h1>
          <p className="font-inter text-sm md:text-base tracking-[2%] font-light">How do we get in touch with you?</p>
        </div>
        <div className=" md:p-4 md:px-10 w-full max-w-md mx-auto grid grid-cols-1 gap-6 ">
          <TextField
            label="Company Name"
            color="warning"
            name="companyName"
            value={userDetails?.companyName || ""}
            onChange={handleChange}
          />
          <TextField
            label="Your Name"
            color="warning"
            name="name"
            value={userDetails?.name || ""}
            onChange={handleChange}
          />
          <TextField
            label="Preferred E-mail"
            color="warning"
            name="email"
            value={userDetails?.email || ""}
            onChange={handleChange}
          />
          <TextField
            label="Preferred Contact Number"
            color="warning"
            InputProps={{
              startAdornment: <InputAdornment position="start">+91</InputAdornment>,
            }}
            name="contactNumber"
            value={userDetails?.contactNumber || ""}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsForm;
