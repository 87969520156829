import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { updateStep } from "redux/startupForm";
import GradientButton from "components/Common/Button/GradientButton";

const IntroForm = () => {
  const dispatch = useDispatch();
  const { step } = useSelector((state) => state.startupForm);
  return (
    <div className="flex flex-col gap-8 p-6 md:p-0  pt-20 md:pt-36 items-center justify-center text-center relative w-full max-w-5xl mx-auto">
      <h1 className="font-poppins font-light  text-3xl md:text-6xl  ">
        Introducing PODs
      </h1>
      <h2 className="font-poppins font-light text-primary-red-500  text-2xl md:text-3xl   ">
        Professionals On Demand
      </h2>
      <p className="font-inter text-md md:text-lg leading-8 md:leading-10 font-light tracking-wide w-full max-w-3xl ">
        We aim to help impact startups meet their innovation and delivery needs
        through student driven functional teams. We do this through a suite of
        entirely remote tools that help manage student work and assure quality
        of delivery
      </p>
      <GradientButton
        text="Start Building Your Dream Team"
        icon={<BiRightArrowAlt className="text-lg" />}
        onClick={() => dispatch(updateStep(step + 1))}
      />
    </div>
  );
};

export default IntroForm;
