import { companyGoals } from "helpers/constants/startupForm";
import { BiCheckCircle, BiCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "redux/startupForm";

const GoalForm = ({ field }) => {
  const { details } = useSelector((state) => state.startupForm);
  const dispatch = useDispatch();

  const handleOptionClick = (option) => {
    let value;
    if (details[field] === option) {
      value = "";
    } else {
      value = option;
    }
    dispatch(updateDetails({ field, value }));
  };

  return (
    <div className="md:px-20 w-full">
      <div className=" p-4 md:p-6 md:px-10 flex flex-col gap-6 md:gap-8 ">
        <div className="flex flex-col gap-2 text-center">
          <h1 className="text-lg md:text-xl font-poppins tracking-[2%]">
            What according to you are the current goals for your startup?
          </h1>
          <p className="font-inter text-sm md:text-base tracking-[2%] font-light">We’ll help you based on your needs</p>
        </div>
        <div className="w-full grid grid-cols-1 sm:grid cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4 md:px-0 py-2 md:py-4 pb-20 md:pb-0 ">
          {companyGoals.map((goal, idx) => {
            const { title, icon } = goal;
            const isSelected = details[field] === title;
            return (
              <div
                key={idx}
                onClick={() => handleOptionClick(title)}
                className={`p-4 flex flex-col gap-4 items-center text-center cursor-pointer border border-primary-grey-200 rounded-lg relative ${
                  isSelected ? "bg-gradient-to-b from-[#FDF7E980] to-[#FFE9E580]" : "bg-white"
                }`}>
                <div className="absolute top-4 right-4">
                  {!isSelected ? (
                    <BiCircle className="text-primary-grey-300 text-xl" />
                  ) : (
                    <BiCheckCircle className="text-primary-yellow-600 text-xl " />
                  )}
                </div>
                <img src={icon} alt="" className="w-18 h-18 object-contain" />
                <p className="font-inter text-sm tracking-[2%]">{title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GoalForm;
