import FormNavigation from "components/StartupForm/FormNavigation";
import { FormComponents } from "helpers/constants/startupForm";
import React from "react";
import { useSelector } from "react-redux";

const renderStepComponent = (step) => {
  const formComponents = FormComponents;
  switch (step) {
    case step:
      return formComponents[step]
        ? formComponents[step].component
        : formComponents[0].component;
    default:
      return formComponents[0].component;
  }
};

const Form = () => {
  const { step } = useSelector((state) => state.startupForm);
  return (
    <div className="relative">
      <div className={`min-h-screen flex flex-col justify-between`}>
        <div className=" bg-white flex p-2 md:p-4 px-4 md:px-12 border-b-2 border-opacity-30 ">
          <img
            src="/assets/logo/chronos-logo.svg"
            alt=""
            className="h-10 object-contain"
          />
        </div>

        <div className="flex-1 overflow-y-scroll scrollbar-hidden w-full max-w-8xl  mx-auto ">
          {renderStepComponent(step)}
        </div>
      </div>
      {FormComponents[step]?.showNavigation && (
        <div>
          <FormNavigation field={FormComponents[step]?.field || ""} />
        </div>
      )}
    </div>
  );
};

export default Form;
