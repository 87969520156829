import React from "react";

const GradientButton = ({ text, icon, onClick, align = "right" }) => {
  return (
    <button
      className={`flex ${
        align === "left" ? "flex-row-reverse" : "flex-row"
      }  items-center gap-2 text-primary-yellow-600  p-3 px-8 rounded-full border-gradient  max-w-max  border border-primary-yellow-600  `}
      onClick={onClick}
    >
      <p className="font-inter text-md md:text-base font-light">{text}</p>
      {icon && icon}
    </button>
  );
};

export default GradientButton;
