import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startups: [],
};

// Storing the startups in the Redux store
export const startups = createSlice({
  name: "startups",
  initialState,
  reducers: {
    updateStartups: (state, action) => {
      state.startups = action.payload;
    },
  },
});

export const { updateStartups } = startups.actions;

export default startups.reducer;
