import Navigation from "components/Common/Navigation";
import Services from "components/Services";

export default function ServicesPage() {
  return (
    <section className="bg-[#fafafa]">
      {/* <Navigation /> */}
      <div className="relative min-h-screen w-full overflow-x-hidden py-12 px-4 max-w-7xl mx-auto space-y-8">
        <div className="flex flex-col items-center space-y-2 text-2xl md:text-[32px] font-light font-satoshi tracking-[2%]">
          <p className="text-black tracking-[2.24px] leading-[42px] text-2xl lg:text-[28px] font-medium text-center">
            A Premium Plan Curated By You
          </p>

          <p className="text-black font-light text-xl lg:text-2xl leading-9 tracking-[0.6px]">
            Select the roles you want right now
          </p>
        </div>
        <Services />
      </div>
    </section>
  );
}
