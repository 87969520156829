import React from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import WelcomePage from "pages/Welcome";
import StartupPage from "pages/Startup";
import ServicesPage from "pages/Services";
import StartupForm from "pages/StartupForm";
import Options from "pages/Options";
import Pricing from "./pages/Pricing";
import GetInTouch from "pages/GetInTouch";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBT3ICtnZR9NWygs78R9pjblLSHKhqV8tk",
  authDomain: "caarya-founder-nexus.firebaseapp.com",
  projectId: "caarya-founder-nexus",
  storageBucket: "caarya-founder-nexus.appspot.com",
  messagingSenderId: "149627382104",
  appId: "1:149627382104:web:8e8f9ae5b4158678e19d2d",
  measurementId: "G-HE8RLGP11K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function App() {
  return (
    <React.Fragment>
      <Toaster
        toastOptions={{
          className: "font-inter text-base rounded",
          success: {
            style: {
              background: "#EBEDF7",
              color: "#1C2135",
              padding: "6px 20px",
            },
          },
          error: {
            style: {
              background: "#EBEDF7",
              color: "#1C2135",
              padding: "6px 20px",
            },
          },
        }}
        position="top-right"
      />
      <div className="min-h-screen block bg-white font-poppins">
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/startups/:startupID" element={<StartupPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/options" element={<Options />} />
          <Route path="/form" element={<StartupForm />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/get-in-touch" element={<GetInTouch />} />
        </Routes>
      </div>
    </React.Fragment>
  );
}

export default App;
